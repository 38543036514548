<template>
  <div>
    <div id="content" class="col-md-12" style="padding-top: 15px">
      <div class="products-category">
        <h3 class="title-category" style="font-size: 2.6rem;font-weight: 700; margin-bottom: 2.5%;">Made In Sri Lanka</h3>
        <!-- <div class="category-desc">
        				<div class="row">
        					<div class="col-sm-12">
        						<div class="banners">
        							<div>
        								<a href="#"><img src="assets/image/catalog/banners/banner.png" alt="img cate" style="width: 1800px; height:300px;"><br></a>
        							</div>
        						</div>
        					
        					</div>
        				</div>
                        
        </div>-->

        <!-- Filters -->
        <!-- <div class="product-filter product-filter-top filters-panel">
          <div class="row">
            <div class="col-md-5 col-sm-3 col-xs-12 view-mode">
              <div class="list-view"> -->
                <!-- <button
                  class="btn btn-default grid"
                  @click.prevent.stop="display('grid')"
                  :class="showmode == 'grid' ? 'active' : ''"
                  data-view="grid"
                  data-toggle="tooltip"
                  data-original-title="Grid"
                >
                  <i class="fa fa-th"></i>
                </button>
                <button
                  class="btn btn-default list"
                  @click.prevent.stop="display('list')"
                  :class="showmode == 'list' ? 'active' : ''"
                  data-view="list"
                  data-toggle="tooltip"
                  data-original-title="List"
                >
                  <i class="fa fa-th-list"></i>
                </button> -->
              <!-- </div>
            </div> -->
            <!-- <div class="short-by-show form-inline text-right col-md-7 col-sm-9 col-xs-12">
              <div class="form-group short-by">
                <label class="control-label" for="input-sort">Sort By:</label>
                <select
                  id="input-sort"
                  class="form-control"
                  v-model="selectedsortStatus"
                  @change="sortProducts()"
                >
                  <option
                    v-for="(item, index) in filterStatusArray"
                    :key="index"
                    :value="item.value"
                    selected="selected"
                  >{{item.text}}</option>
                </select>
              </div>
              <div class="form-group">
                <label class="control-label" for="input-limit">Show:</label>
                <select
                  id="input-limit"
                  class="form-control"
                  v-model="selectedProductCount"
                  @change="changeProductCount()"
                >
                  <option
                    v-for="(item, index) in filterProductCount"
                    :key="index"
                    :value="item.value"
                    selected="selected"
                  >{{item.text}}</option>
                </select>
              </div>
            </div> -->
            <!-- <div class="box-pagination col-md-3 col-sm-4 col-xs-12 text-right">
                                <ul class="pagination">
                                    <li class="active"><span>1</span></li>
                                    <li><a href="">2</a></li><li><a href="">&gt;</a></li>
                                    <li><a href="">&gt;|</a></li>
                                </ul>
            </div>-->
          <!-- </div>
        </div> -->
        <!-- //end Filters -->

        <!--changed listings-->
        <div class="products-list row nopadding-xs so-filter-gird">
          <!-- <div class="col-md-12" id="preview-area" v-if="blockLoading">
                            <div class="row text-center">
                                <div class="sk-chase">
                                    <div class="sk-chase-dot"></div>
                                    <div class="sk-chase-dot"></div>
                                    <div class="sk-chase-dot"></div>
                                    <div class="sk-chase-dot"></div>
                                    <div class="sk-chase-dot"></div>
                                    <div class="sk-chase-dot"></div>
                                </div>
                            </div>
          </div>-->

          <div class="col-md-12" v-if="!blockLoading && blocks.length === 0">
            <h4>No Items Found</h4>
          </div>

          <div v-for="(item, index) in blocks" :key="index">
            <component
              :is="item.blockdatacomponent"
              v-bind:noslide="true"
              v-bind:data="item"
            ></component>
          </div>
          <div
            id="infiniteScrollLoader"
            class="text-center"
            v-infinite-scroll="loadMore"
            infinite-scroll-disabled="busy"
            infinite-scroll-distance="30"
          >
            <img src="/assets/image/product/loader.gif" />
     <h4>LOADING...</h4>
          </div>
        </div>
        <!--// End Changed listings-->
        <!-- Filters -->
        <!-- <div class="product-filter product-filter-bottom filters-panel">
          <div class="row">
            <div class="col-sm-6 text-left"></div>
            <div class="col-sm-6 text-right">Showing 1 to 15 of 15 (1 Pages)</div>
          </div>
        </div> -->
        <!-- //end Filters -->
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="productModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="productModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header"  style="display: flex; justify-content: space-between; align-items: end;">
            <h5 class="modal-title" id="productModalLongTitle">
              Product Quick View
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              style="margin-top: -20px"
            >
              <span style="font-size: 30px; font-weight: initial; color: black; color: black !important;" aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ProductModalContent v-bind:pid="this.product_id" />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              style="background-color: white !important; border: 1px solid black !important; border-radius: 5px; color: black;"
            >
              Close
            </button>
            <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProductTile from "@/components/product/product_tile_new.vue";
import ProductTileBrand from "@/components/product/product_tile_brand.vue";
import ProductTileGrid from "@/components/product/product_tile_grid.vue";

import ProductModalContent from "@/components/product/product_modal.vue";

import mixinProduct from "@/mixins/buyer/APIProduct";
import { async } from "q";

export default {
  components: {
    ProductTile,
    ProductTileBrand,
    ProductTileGrid,
    ProductModalContent,
  },
  mixins: [mixinProduct],
  data() {
    return {
      breadcrumb: [],
      cID: "",
      pname: "",
      product_id: "",
      blocks: [],
      filterStatusArray: [
        { value: "", text: "Default" },
        { value: "0", text: "Product Name (A - Z)" },
        { value: "1", text: "Product Name (Z - A)" },
        { value: "2", text: "Price (Low - High)" },
        { value: "3", text: "Price (High - Low)" },
        { value: "4", text: "Rating (High - Low)" },
        { value: "5", text: "Rating (Low - High)" },
        { value: "6", text: "Model (A - Z)" },
        { value: "7", text: "Model (Z - A)" },
      ],
      selectedsortStatus: "",
      filterProductCount: [
        { value: "15", text: "15" },
        { value: "25", text: "25" },
        { value: "50", text: "50" },
        { value: "75", text: "75" },
        { value: "100", text: "100" },
      ],
      selectedProductCount: "15",
      showmode: "list",
      blockLoading: true,
      busy: false,
      pagicount: 1,
      stoppagi: false,
      historyparams: "",
    };
  },
  watch: {
    $route(to, from) {
      this.$forceUpdate();
      this.callFucntionsByOrder();
    },
    cID: function () {
      this.blocks = [];
      this.stoppagi = false;
      this.pagicount = 1;
      this.loadMore(0);
    },
    pname: function () {
      this.blocks = [];
      this.stoppagi = false;
      this.pagicount = 1;
      this.loadMore(0);
    },
    "$route.query.id"() {
      this.$forceUpdate();
      this.callFucntionsByOrder();
    },
    "$store.state.filterdata": function () {
      this.blocks = [];
      this.pagicount = 1;
      this.getProductsByID();
    },
  },
  mounted: function () {
    this.handleBreadcrumb();

    this.display("grid");
  },
  created() {
    this.initiateList();
    this.callFucntionsByOrder();
    // this.handleGetStores();
  },
  methods: {
    getURLcID() {
      let url = window.location.href.split("id=");
      if (url.length > 1) {
        this.cID = url[1];
      } else {
        this.cID = "";
      }

      return url[1];
    },
    getURLpname() {
      let url = window.location.href.split("name=");
      this.pname = url[1];
      if (url.length > 1) {
        this.pname = url[1];
      } else {
        this.pname = "";
      }

      return url[1];
    },
    callFucntionsByOrder: async function () {
      localStorage.setItem("filterdata", null);

      this.$store.dispatch("fetchFilterData");
      this.getURLcID();
      this.getURLpname();
      // await this.getProductsByID();
    },
    handleBreadcrumb: function () {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb,
      });
    },
    getProductsByID: async function (currentpage) {
      this.blockLoading = true;

      try {
        // this.blocks = [];
        let params = "";
        if (this.cID !== "") {
          params += "category=" + this.cID;
        }

        if (this.pname !== "") {
          params += "&name=" + this.pname;
        }

        if (this.params !== "") {
          params += "madein_srilanka=true";
        }

        params += "&page=" + currentpage;
        params += "&perpage=" + this.selectedProductCount;

        if (this.$store.state.filterdata != null) {
          if (this.$store.state.filterdata.brands.length > 0) {
            for (
              let index = 0;
              index < this.$store.state.filterdata.brands.length;
              index++
            ) {
              params += "&brands=" + this.$store.state.filterdata.brands[index];
            }
          }
          if (this.$store.state.filterdata.attributes.length > 0) {
            for (
              let index = 0;
              index < this.$store.state.filterdata.attributes.length;
              index++
            ) {
              params +=
                "&attribute_values=" +
                this.$store.state.filterdata.attributes[index];
            }
          }

          if (this.$store.state.filterdata.searchtext != "") {
            params += "&name=" + this.$store.state.filterdata.searchtext;
          }

          // console.log(this.$store.state.filterdata);
        }

        if (this.historyparams === params) {
          return;
        } else {
          this.historyparams = params;
        }

        // console.log(params);
        let productlist = await this.getProductListByCIDFilters(params);
        // console.log(productlist);
        let blockdata = [];
        for (let index = 0; index < productlist.length; index++) {
          //  console.log(productlist[index]._id);
          let imgs = [];
          let imagearray = productlist[index].show_image;
          for (let index2 = 0; index2 < imagearray.length; index2++) {
            let imgpath = imagearray[index2].image;
            // let is1 = imgpath.split("upload/")[0];
            // let is4 = imgpath.split("upload/")[1];
            // let is2 = "upload/q_auto,f_auto/w_300,h_300,c_fill/";
            // let is3 = is4.split("/")[1];
            // imgpath = is1 + is2 + is4;
            imgs.push(imgpath);
          }

          let oldprice = "";
          let newprice = "";
          if (
            productlist[index].simple_products[0].special_price != 0 &&
            productlist[index].simple_products[0].special_price != ""
          ) {
            newprice = productlist[index].simple_products[0].special_price;
            oldprice = productlist[index].simple_products[0].price;
          } else {
            newprice = productlist[index].simple_products[0].price;
            oldprice = "";
          }

          let discounpre = "";

          if (oldprice != "") {
            discounpre = ((oldprice - newprice) / oldprice) * 100;
            discounpre = Math.round(discounpre);
          }

          blockdata.push({
            blockdatacomponent: "ProductTile",
            discountprecentage: discounpre,
            imgpath: imgs,
            item_id: productlist[index]._id,
            prediscount: oldprice,
            price: newprice,
            product_name: productlist[index].product_name,
            primary_product: productlist[index].primary_product,
            model: productlist[index].model,
            rating: productlist[index].rate.toFixed(2).replace(/\.00$/, ""),
            title: productlist[index].product_name,
            store: productlist[index].store,
            url: "/product/" + productlist[index]._id,
            wishlit: productlist[index].wishlit,
            madein_srilanka: true
          });
        }
        // console.log("madeIn",blockdata);
        // this.blocks = blockdata;

        blockdata.forEach((element) => {
          this.blocks.push(element);
        });
        this.blockLoading = false;
      } catch (error) {
        this.blockLoading = false;
      }
    },
    sortProducts() {
      // console.log(this.selectedsortStatus);
      if (this.selectedsortStatus === "") {
        location.reload();
      }
      if (this.selectedsortStatus === "0") {
        this.blocks.sort(function (a, b) {
          var x = a.product_name.toLowerCase();
          var y = b.product_name.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });
      }
      if (this.selectedsortStatus === "1") {
        this.blocks.sort(function (a, b) {
          var x = a.product_name.toLowerCase();
          var y = b.product_name.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });
        this.blocks.reverse();
      }
      if (this.selectedsortStatus === "2") {
        this.blocks.sort(function (a, b) {
          return a.price - b.price;
        });
      }
      if (this.selectedsortStatus === "3") {
        this.blocks.sort(function (a, b) {
          return a.price - b.price;
        });
        this.blocks.reverse();
      }
      if (this.selectedsortStatus === "4") {
        this.blocks.sort(function (a, b) {
          return a.rating - b.rating;
        });
        this.blocks.reverse();
      }
      if (this.selectedsortStatus === "5") {
        this.blocks.sort(function (a, b) {
          return a.rating - b.rating;
        });
      }
      if (this.selectedsortStatus === "6") {
        this.blocks.sort(function (a, b) {
          var x = a.model.toLowerCase();
          var y = b.model.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });
      }
      if (this.selectedsortStatus === "7") {
        this.blocks.sort(function (a, b) {
          var x = a.model.toLowerCase();
          var y = b.model.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });
        this.blocks.reverse();
      }
      this.getProductsByID();
    },
    initiateList() {
      let self = this;
      $(".list-view .btn").each(function () {
        $(this).siblings(".btn").removeClass("active");
      });
    },
    display(view) {
      $(".products-list").removeClass("list grid").addClass(view);
      $(".list-view .btn").removeClass("active");
      if (view == "list") {
        //$('.products-list .product-layout').addClass('col-lg-12');
        // $('.products-list .product-layout .left-block').addClass('col-md-4');
        // $('.products-list .product-layout .right-block').addClass('col-md-8');
        $(".products-list .product-layout .item-desc").removeClass("hidden");
        $(".products-list .product-layout .list-block").removeClass("hidden");
        $(".products-list .product-layout .button-group").addClass("hidden");
        $(".list-view ." + view).addClass("active");
        $(".list-view .btn").removeClass("active");
        $.cookie("display", "list");
      } else {
        //$('.products-list .product-layout').removeClass('col-lg-12');
        //$('.products-list .product-layout .left-block').removeClass('col-md-4');
        //$('.products-list .product-layout .right-block').removeClass('col-md-8');
        $(".products-list .product-layout .item-desc").addClass("hidden");
        $(".products-list .product-layout .list-block").addClass("hidden");
        $(".products-list .product-layout .button-group").removeClass("hidden");
        $(".list-view ." + view).addClass("active");
        $(".list-view .btn").removeClass("active");
        $.cookie("display", "grid");
      }

      this.showmode = view;
    },
    pushToProduct() {
      this.$router.push("/product");
    },
    parentModalTrigger(pid) {
      this.product_id = pid;

      this.inputNumValue = 1;
      this.isDisable = true;
      this.idObj = {
        ids: [],
      };

      //    this.setProductInfo();
      $("#productModalCenter").modal("show");
    },
    loadMore: async function (currentpage) {
      if (!this.stoppagi) {
        this.busy = true;

        await this.getProductsByID(this.pagicount);
        let datacount = this.pagicount * this.selectedProductCount;
        if (this.blocks.length === datacount) {
          this.stoppagi = false;
        } else {
          this.stoppagi = true;
          document.getElementById("infiniteScrollLoader").style.display =
            "none";
        }

        this.pagicount++;
        this.busy = false;
      }
    },
    changeProductCount() {
      this.blocks = [];
      this.stoppagi = false;
      this.busy = false;
      this.pagicount = 2;
      document.getElementById("infiniteScrollLoader").style.display = "block";
      this.getProductsByID(1);
    },
  },
};
</script>
<style>
#productModalLongTitle {
  font-size: 18px;
  color: black;
  width: fit-content;
}

.title-category:after {
  background-color: #ffd338;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.m0p0 {
  margin: 0px !important;
  padding: 0px !important;
}
</style>